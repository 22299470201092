<template>
  <!--TODO: This component uses a style directive on element, needs to be refactored w/ tailwind-->
  <div class="w-full py-6">
    <div class="flex">
      <div v-for="item in items" :key="item" class="w-1/4">
        <div class="relative mb-2">
          <div
            v-if="item.index > 1"
            class="absolute flex z-0"
            style="width: calc(77%); top: 50%; transform: translate(-50%, -50%)"
          >
            <div
              :class="[
                'w-full flex-1',
                {
                  'bg-purple': item.index <= current_step,
                  'bg-wizard-gray': item.index > current_step,
                },
              ]"
            >
              <div class="py-0.5" />
            </div>
          </div>
          <div
            :class="[
              'relative w-10 h-10 mx-auto bg-white border rounded-full text-lg flex items-center z-10',
              {
                'bg-purple text-white border-purple': item.index < current_step,
                'border-purple text-purple': item.index === current_step,
                'border-wizard-gray': item.index > current_step,
              },
            ]"
          >
            <span class="text-center w-full">{{ item.index }}</span>
          </div>
        </div>
        <div
          :class="{
            'text-dark-purple': item.index <= current_step,
          }"
          class="text-center"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanWizardStepTracker",
  props: {
    items: {
      type: Array,
    },
    current_step: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      /*items: [
        {
          index: 2,
          title: "Select Experiences",
        },
        {
          index: 3,
          title: "Review Plan",
        },
        {
          index: 4,
          title: "Submit Plan",
        },
      ],*/
    };
  },
};
</script>

<style scoped></style>
