<template>
  <div class="">
    <div v-if="current_plan_step === 1">
      <div class="py-2">
        <h3 class="thirdhead-text" v-text="'Mentor'" />
        <p class="text-lg">
          You are creating a plan for your mentor:
          <strong
            >{{ reviewing_mentor.firstname }}
            {{ reviewing_mentor.lastname }}</strong
          >
        </p>
      </div>
      <div class="py-2">
        <h3
          class="thirdhead-text"
          v-text="'Personal Ethics Mission Statement'"
        />
        <p
          class="text-lg"
          v-text="
            'Enter a description that reflects your first principles, core values, and over-arching goals.'
          "
        />
        <div class="pt-3">
          <textarea
            data-cy="plan-personal-ethics-textarea"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-36 w-full"
            placeholder="Enter description here."
            v-model="local_plan.ethics_statement"
          />
        </div>
      </div>
      <div class="py-2">
        <h3 class="thirdhead-text" v-text="'Debriefing Topics'" />
        <p
          class="text-lg"
          v-text="
            'Identify at least two substantive topics to discuss in detail with your mentor later during the year. These could be talking about a particular experience, another professional topic of interest, or one of the debrief templates.'
          "
        />
        <div class="pt-3">
          <textarea
            data-cy="plan-debriefing-topics-textarea"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-36 w-full"
            placeholder="Enter description here."
            v-model="local_plan.debrief_topics"
          />
        </div>
      </div>
      <div class="py-2">
        <h3 class="thirdhead-text" v-text="'Additional Notes'" />
        <p
          class="text-lg"
          v-text="
            'Enter a description of other skills or competencies you would like to develop, enhance, or get feedback on from your mentor.'
          "
        />
        <div class="pt-3">
          <textarea
            data-cy="plan-additional-notes-textarea"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-36 w-full"
            placeholder="Enter description here."
            v-model="local_plan.comments"
          />
        </div>
      </div>
    </div>
    <div v-if="current_plan_step === 2">
      <div class="py-2">
        <h3
          class="thirdhead-text"
          v-text="
            'Identified Experiences Corresponding to the Law Curriculum and Associated Legal or Practice Areas'
          "
        />
        <p
          class="text-lg"
          v-text="'Search our listing of over 500 experiences to choose from.'"
        />
        <div class="pt-3">
          <div class="pb-5 border-b border-cool-gray">
            <ul class="rounded mb-2">
              <li
                class="flex justify-between p-2 even:bg-cool-white odd:bg-light-gray first:rounded-t last:rounded-b"
                v-for="(exp, idx) in legal_community_experiences"
                :key="idx"
              >
                <div class="grow justify-start">
                  {{ exp.description }}
                </div>
                <div class="flex-none">
                  <button
                    :data-cy="`plan-remove-experience-${idx}-button`"
                    @click="
                      removeExperience('legal_community_experiences', idx)
                    "
                    class="text-dark-purple rounded-full"
                  >
                    Remove
                  </button>
                </div>
              </li>
            </ul>
            <button
              data-cy="plan-lookup-experiences-button"
              class="inline-block py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
              @click="toggleModal()"
            >
              Look Up Experiences
            </button>
            <LookupModal
              v-if="modalOpen"
              :categories="legal_experiences_options"
              :label="modal_label"
              @closeModal="toggleModal(1)"
              @addSelection="pushSelection"
            />
          </div>
        </div>
        <div class="pt-3"></div>
      </div>
      <div class="py-2">
        <h3 class="thirdhead-text" v-text="'Wild Card Experiences'" />
        <p
          class="text-lg"
          v-text="
            'Experiences you and your mentor plan to do together that are not on the identified experience list. '
          "
        />
        <div
          v-for="(wildcard, index) in wildcard_experiences"
          :key="index"
          class="py-1"
        >
          <div class="not-sr-only">Enter descriptive name</div>
          <!--This is an accessibility element that can be made invisible -->
          <div class="flex justify-between items-center">
            <div class="grow">
              <input
                type="text"
                class="border rounded w-full"
                v-model="wildcard.description"
              />
            </div>
            <div class="flex-none py-3 px-2">
              <button
                class="py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
                @click="removeExperience('wildcard_experiences', index)"
              >
                <font-awesome-icon icon="times" class="text-xs mr-1" /> Remove
              </button>
            </div>
          </div>
        </div>
        <div class="pt-1">
          <button
            class="inline-block py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
            @click="new_wildcard_experience('wildcard')"
          >
            <font-awesome-icon icon="plus" class="text-xs mr-1" /> Add
          </button>
        </div>
      </div>
      <div class="py-2">
        <h3 class="thirdhead-text" v-text="'Director-Approved Experiences'" />
        <p
          class="text-lg"
          v-text="
            'Suggest experiences you would like to engage in with attorneys or judges other than your mentor. These must be approved by your program director in advance of the experience. '
          "
        />
        <div
          v-for="(da_experience, index) in director_approved_experiences"
          :key="index"
          class="py-1"
        >
          <div class="not-sr-only">Enter descriptive name</div>
          <!--This is an accessibility element that can be made invisible -->
          <div class="flex justify-between items-center">
            <div class="grow">
              <input
                type="text"
                class="border rounded w-full"
                v-model="da_experience.description"
              />
            </div>
            <div class="flex-none py-3 px-2">
              <button
                class="py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
                @click="removeExperience('director_approved_experiences')"
              >
                <font-awesome-icon icon="times" class="text-xs mr-1" /> Remove
              </button>
            </div>
          </div>
        </div>
        <div class="pt-1">
          <button
            class="inline-block py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
            @click="new_wildcard_experience('director')"
          >
            <font-awesome-icon icon="plus" class="text-xs mr-1" /> Add
          </button>
        </div>
      </div>
    </div>
    <div v-if="current_plan_step === 3">
      <div class="py-2">
        <div class="py-3 flex">
          <input
            type="checkbox"
            id="cert_3"
            v-model="local_plan.mn_prof_conduct"
          />
          <label
            for="cert_3"
            class="px-2"
            v-html="
              `I understand I will be certified as a student observer under Minnesota Supervised Practice Rule 2E, unless my mentor and I are notified otherwise.`
            "
          />
        </div>
        <div class="py-3 flex">
          <input
            type="checkbox"
            id="cert_2"
            v-model="local_plan.mn_confidentiality_cert"
          />
          <label
            for="cert_2"
            class="px-2"
            v-html="
              `I certify that I have read Rule 1.6 of the Minnesota Rules of
                Professional Conduct, and I will maintain confidentiality and
                will ensure that I am identified as a student to any client and
                accepted by them. (See Mentor Externship Canvas)`
            "
          />
        </div>
        <div class="py-3 flex">
          <input
            type="checkbox"
            id="cert_1"
            v-model="local_plan.misconduct_disclaimer"
          />
          <label
            for="cert_1"
            class="px-2"
            v-text="
              `I understand that I am responsible for the content and accuracy
                of all submissions in connection with Mentor Externship and that
                any submission I make that is false or misleading, and any
                dishonesty or violations of the rules governing my
                responsibilities in the program may constitute &quot;academic
            misconduct&quot; in accord with the Code of Student Responsibility.`
            "
          />
        </div>
      </div>
    </div>
    <div v-if="current_plan_step === 4">
      <div>
        <div>
          <span class="font-semibold">Student Name: </span>
          {{ student.firstname }} {{ student.lastname }}
        </div>
        <div>
          <span class="font-semibold">Mentor Name:</span>
          {{ reviewing_mentor?.firstname }}
          {{ reviewing_mentor?.lastname }}
        </div>
      </div>
      <div class="mt-3">
        <h3 class="thirdhead-text" v-text="'Personal Ethics Statement'" />
        <div v-if="local_plan.ethics_statement">
          {{ local_plan.ethics_statement }}
        </div>
      </div>
      <h3 class="thirdhead-text" v-text="'Debriefing Topics'" />
      <div v-if="local_plan.debrief_topics">
        {{ local_plan.debrief_topics }}
      </div>
      <h3 class="thirdhead-text" v-text="'Additional Notes'" />
      <div v-if="local_plan.comments">
        {{ local_plan.comments }}
      </div>
      <div class="py-2">
        <h3 class="thirdhead-text" v-text="'Experiences'" />
        <div
          v-for="(experience, index) in experiences"
          :key="`experience_${index}`"
        >
          {{ experience.description }}
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        v-if="current_plan_step !== 5"
        data-cy="plan-print-button"
        class="inline-block py-1 px-6 bg-purple hover:bg-dark-purple rounded-full text-white"
        @click.prevent="
          current_plan_step === 4 ? printPreview() : validateAndSave()
        "
      >
        {{ current_plan_step === 4 ? "Review" : "Save" }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import CategoryLookupModal from "@/components/lookup/CategoryLookupModal";
import LookupModal from "@/components/lookup/LookupModal";

export default {
  name: "PlanWizard",
  emits: ["save-progress", "errors"],
  components: { LookupModal },
  props: {
    current_plan_step: {
      type: Number,
      required: true,
    },
    mentor_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      local_plan: {
        ppdp_id: null,
        ethics_statement: null,
        comments: null,
        debrief_topics: null,
        mn_confidentiality_cert: null,
        mn_prof_conduct: null,
        misconduct_disclaimer: null,
        status: null,
        student_at_level_id: null,
        reviewing_mentor_id: null,
        experiences: [],
      },

      modalOpen: false,
      show_submit_modal: true,
      modal_label: "Identified Experiences",

      legal_community_experiences: [],
      wildcard_experiences: [],
      director_approved_experiences: [],
    };
  },
  created() {
    if (this.plan.plans[this.mentor_id]) {
      this.local_plan = this.plan.plans[this.mentor_id];
      this.experiences = this.local_plan.experiences;
    } else {
      this.local_plan.student_at_level_id =
        this.student_profile.student_at_level_id;
      this.local_plan.reviewing_mentor_id = this.mentor_id;
    }
  },
  methods: {
    pushSelection: function (selection) {
      selection.selection.forEach((e) => {
        this.legal_community_experiences.push({
          experience_id: e.experience_id,
          description: e.description,
        });
      });
      this.modalOpen = false;
    },
    removeExperience: function (type, index) {
      this[type].splice(index, 1);
    },
    new_wildcard_experience(type) {
      if (type === "wildcard") {
        this.wildcard_experiences.push({
          experience_id:
            this.wildcard_type.categories[0].experiences[0].experience_id,
          description: "",
        });
      } else {
        this.director_approved_experiences.push({
          experience_id:
            this.director_approved_type.categories[0].experiences[0]
              .experience_id,
          description: "",
        });
      }
    },
    toggleModal: function () {
      this.modalOpen = !this.modalOpen;
    },
    printPreview: function () {
      this.$router.push("/plan/view/" + this.mentor_id);
    },
    validateAndSave() {
      if (!this.validate()) {
        this.saveProgress();
      }
    },
    saveProgress: function () {
      this.local_plan.experiences = this.experiences;
      this.$emit("save-progress", this.local_plan);
    },
    validate() {
      let errorMsgs = [];
      switch (this.current_plan_step) {
        case 1:
          if (!this.local_plan.ethics_statement) {
            errorMsgs.push("Please enter Personal Ethics Mission Statement");
          }
          if (!this.local_plan.debrief_topics) {
            errorMsgs.push("Please enter at least two Debriefing Topics");
          }
          break;
        case 2:
          if (!this.experiences.length) {
            errorMsgs.push(
              "Please identify experiences that you're interested in doing this year"
            );
          }
          break;
        case 3:
          if (
            !this.local_plan.mn_prof_conduct ||
            !this.local_plan.mn_confidentiality_cert ||
            !this.local_plan.misconduct_disclaimer
          ) {
            errorMsgs.push("Please complete all three certifications.");
          }
          break;
      }
      this.$emit("errors", errorMsgs);
      return errorMsgs.length;
    },
    ...mapActions(["fetchLookupResource", "updatePlan"]),
    ...mapMutations([""]),
  },
  computed: {
    ...mapState({
      student: (state) => state.student,
      plan: (state) => state.plan,
    }),
    ...mapGetters([
      "student_mentors",
      "student_profile",
      "lookup_experiences",
      "student_name",
    ]),
    reviewing_mentor() {
      return this.student_mentors.find(
        (mentor) => mentor.mentor_id === this.mentor_id
      );
    },
    experiences: {
      get() {
        return [
          ...this.legal_community_experiences,
          ...this.wildcard_experiences,
          ...this.director_approved_experiences,
        ];
      },
      set(newValue = []) {
        this.lookup_experiences.forEach((type) => {
          type.categories.forEach((cat) => {
            cat.experiences.forEach((exp) => {
              if (type.description === "Wild Card Experience") {
                newValue.forEach((nv) => {
                  if (exp.experience_id === nv.experience_id) {
                    this.wildcard_experiences.push(nv);
                  }
                });
              } else if (type.description === "Director-Approved Experience") {
                newValue.forEach((nv) => {
                  if (exp.experience_id === nv.experience_id) {
                    this.director_approved_experiences.push(nv);
                  }
                });
              } else {
                newValue.forEach((nv) => {
                  if (exp.experience_id === nv.experience_id) {
                    this.pushSelection({ selection: [exp] });
                  }
                });
              }
            });
          });
        });
      },
    },
    director_approved_type() {
      return this.lookup_experiences.find(
        (exp) => exp.description === "Director-Approved Experience"
      );
    },
    wildcard_type() {
      return this.lookup_experiences.find(
        (exp) => exp.description === "Wild Card Experience"
      );
    },
    legal_experiences_options() {
      const exps = {
        ...this.lookup_experiences.find(
          (exp) =>
            exp.description ===
            "Identified Experiences Corresponding to the Law Curriculum and Associated Legal or Practice Areas"
        ),
      };

      if (this.legal_community_experiences.length > 0) {
        exps.categories.forEach((cat) => {
          cat.experiences = cat.experiences.filter(
            (exp) =>
              this.legal_community_experiences.findIndex(
                (e) => e.experience_id === exp.experience_id
              ) < 0
          );
        });
      }
      exps.categories = exps.categories.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
      let categories = {};
      exps.categories.forEach((cat) => {
        categories[cat.description] = {
          items: cat.experiences,
          name: cat.description,
          open: true,
          // Add ID here
        };
      });
      return categories;
    },
  },
};
</script>

<style scoped></style>
